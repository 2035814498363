body{
  background: linear-gradient(180deg, rgba(214, 210, 214, 0.514), rgba(216, 214, 214, 0.274)),url("./Media/backgroundstripe.png");
  height: 100%;
}
.loading{
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading::after{
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: rgb(79, 172, 201);
  border-bottom-color: rgba(184, 181, 181, 0.877);
  border-radius: 50%;
  animation: loading 1s linear infinite!important;
}
@keyframes loading {
  to{
      transform:rotate(1turn);
  }
}